import React, { Component } from "react";

class Soberfaqscript extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What kinds of treatments and programs are available?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "While every center we operate offers slightly different programming, the Taste Recovery name means consistency. There is a standard that all our locations aim for. All of the support we offer at our sober living houses is focused on thorough and reliable recovery. Some activities and therapies in support of that mission include: • Group therapies, • Addiction treatment, • Peer support groups, • Individual therapy, • And more.",
          },
        },
        {
          "@type": "Question",
          name: "What are the house rules at your men’s or women’s sober houses?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Each location will have slightly different rules, but there is one big one. Each of our houses operate as a clean, substance-free setting so that all residents can progress according to their goals. When you check into a location, your house manager will be able to provide detailed house rules for you.",
          },
        },
        {
          "@type": "Question",
          name: "How can I get into a location?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Once you’ve identified a sober home that works for you, simply reach out to our admissions team. We can help you get up to speed on pricing, insurance considerations, move-in schedules, and more.",
          },
        },
      ],
    });
    document.head.appendChild(script);
    this.script = script; // Save reference for cleanup
  }

  componentWillUnmount() {
    if (this.script) {
      document.head.removeChild(this.script);
    }
  }

  render() {
    return null; // No UI for this component
  }
}

export default Soberfaqscript;
