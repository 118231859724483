import React, { useEffect } from "react";

const FAQSchemacalofornia = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "SOBER LIVING HOUSES IN CALIFORNIA?",
          acceptedAnswer: {
            "@type": "Answer",
            text: `Each of our recovery centers is designed around one thing: reliable care that empowers better and healthier lifestyles. 
                    Those seeking refuge and a new start can rely on the Taste Recovery staff. Our team has been trained and certified to 
                    provide the best care possible at our California sober living centers.\n\nBrowse our sober living homes in California above. 
                    We have placed them strategically across the state to ensure convenience. You don’t need to uproot your life to get help. 
                    Patients from nearly every corner of California can get access to the substance care they need.\n\nAs you browse our 
                    locations, note the services and treatments available to patients at each location. You’ll notice that each treatment center 
                    offers slightly different services to ensure the best for their patients. These services include:\n\n- Group therapies\n- Addiction 
                    treatment services\n- Peer support groups\n- Art and music therapy\n- And more.\n\nWe know many patients bounce from one to 
                    the next sober living houses in California. Our approach to recovery is built on the ever-changing care you need. As your 
                    recovery evolves, we are equipped to evolve with you.`,
          },
        },
        {
          "@type": "Question",
          name: "SOBER LIVING IN CALIFORNIA LOCATIONS?",
          acceptedAnswer: {
            "@type": "Answer",
            text: `We offer our proven treatment programs and living environments for patients throughout California. Each of our 
                    California sober living homes is designed to be comprehensive and reliable. Trust in us as a partner on your road to 
                    recovery, even close to home.\n\nWe help support many of California’s most populous communities. Consider our sober 
                    living facilities in:\n\n- Los Angeles\n- Newport Beach\n- Orange County\n- And more.\n\nUnlike other rehab programs, we 
                    offer holistic, comprehensive, and personalized care to every one of our patients. Whether you struggle with drug addiction, 
                    alcohol abuse, or more, trust in Taste Recovery. We are equipped to help you begin your journey toward recovery.\n\nClick 
                    on any of the sober living homes in California above to learn more about a particular location. When you are ready, feel 
                    free to email or call that sober home’s admissions staff. They can offer more information about particular treatments and 
                    can set up a tour.\n\nWe also offer treatment centers for addiction, substance use disorders, mental health disorders, and 
                    more throughout the U.S.\n\nGet in touch with one of our California sober living homes today.`,
          },
        },
      ],
    });

    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default FAQSchemacalofornia;
