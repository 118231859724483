import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-rater/lib/react-rater.css";
import "./assets/font/stylesheet.css";
import "rc-slider/assets/index.css";
import "./assets/style.css";
import "./App.css";
import { Routes } from "./router/routes";
import { LastLocationProvider } from "react-router-last-location";
import { PersistGate } from "redux-persist/integration/react";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { deleteOldCache } from "./utils/common.util";
import SessionTimeout from "./constants/SessionTimeout";
import { Helmet } from "react-helmet";
import FooterPopUp from "./components/modal/FooterPopUp";
export default function App({ store, persistor }) {
  // effects
  React.useEffect(() => {
    deleteOldCache();
    // window.addEventListener("offline", () => console.log("Offline"));
    return () => {
      // window.removeEventListener("offline", () => console.log("Online"));
    };
  }, []);
  return (
    <Provider store={store}>
      {/* <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}> */}
      <BrowserRouter>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={null}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={null}>
            <LastLocationProvider>
              <SpinnerComponent>
                {" "}
                <SessionTimeout>
                  <Routes />
                </SessionTimeout>
              </SpinnerComponent>
            </LastLocationProvider>
          </React.Suspense>
        </PersistGate>
     {/* <FooterPopUp /> */}
      </BrowserRouter>
    </Provider>
  );
}
