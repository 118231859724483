import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Sobercontent = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "TOP 7 US STATES FOR SOBER LIVING",
      answer: (
        <>
          <p className="mb-2">
            Discover sober living facilities and independent living
            accommodations throughout the United States :
            <br />
            <a href="#top7section">TOP 7 US STATES FOR SOBER LIVING</a>
          </p>
        </>
      ),
    },
    {
      question: "SOBER LIVING FOR MEN",
      answer: (
        <>
          <p className="mb-2">
            There are sober living homes for men available to start your
            long-term recovery journey today. Access communal living and a
            supportive sober living environment. All of this, along with expert
            staff, help support your goal of a better and healthier life. Browse
            sober living houses for men below:
            <br />
            <a href="#topgenderSection">SOBER LIVING FOR MEN</a>
          </p>
        </>
      ),
    },

    {
      question: "SOBER LIVING FOR WOMEN",
      answer: (
        <>
          <p className="mb-2">
            Find sober living homes for women. These are designed as a safe
            space for those struggling with addiction challenges. Enjoy
            countless forms of help as you enjoy these comfortable homes,
            including support groups, holistic therapies, and more. Browse sober
            living houses for women below:
            <br />
            <a href="#topgenderSection">SOBER LIVING FOR WOMEN</a>
          </p>
        </>
      ),
    },

    {
      question: "SOBER LIVING FOR LGBTQ",
      answer: (
        <>
          <p className="mb-2">
            The queer-friendly recovery residences are an allied support system
            to lean on during times of need. Each living house offers sober
            living programs and policies that inspire inclusion,
            non-discrimination, peer mentorship, and more. Helpful staff work to
            uphold those values as you seek support. Browse sober living houses
            for the LGBTQ community below:
            <br />
            <a href="#topgenderSection">SOBER LIVING FOR LGBTQ</a>
          </p>
        </>
      ),
    },

    {
      question: "RECOMMENDED SOBER LIVINGS",
      answer: (
        <>
          <p className="mb-2">
            Discover the perfect sober living residence for your location and
            needs:
            <br />
            <a href="#topRecommendedsection">RECOMMENDED SOBER LIVINGS</a>
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">TASTE RECOVERY</h2>
        </div>

        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sobercontent;
