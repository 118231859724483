import React from "react";
import FAQSchema from "./FAQSchema";

const Phpheader = () => {
  return (
    <div className="container">
      <FAQSchema />
      <div className="faq-content py-0">
        <div className="faq-content-block py-4 mt-2">
          <h1 className="faqs mb-0 text-center">
            Find a PHP Rehab & Treatment Center Near You
          </h1>
          <p>
            If you or a loved one require PHP rehab, rest assured. Browse the
            treatment centers above for more details about each location. Or
            peruse the frequently asked questions below to learn more about PHP
            program options.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Phpheader;
