import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Californiafaq = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question: "SOBER LIVING HOUSES IN CALIFORNIA?",
      answer: (
        <>
          <p>
            Each home is designed around one thing: reliable care that empowers
            better and healthier lifestyles. Their teams are ready to provide
            quality care in California sober living houses, allowing you to seek
            refuge and gain a fresh start.
          </p>
          <p>
            Browse sober living homes in California above. You don’t need to
            uproot your life to get help. Patients from nearly every corner of
            California can get access to the lifestyle they need.
          </p>
          <p>
            Note the services and treatments available to patients at each
            location. You’ll notice that each sober living home offers slightly
            different services to ensure the best for their patients.
          </p>
        </>
      ),
    },
    {
      question: "SOBER LIVING IN CALIFORNIA LOCATIONS?",
      answer: (
        <>
          <p className="mb-2">
            Explore sober living environments for patients throughout
            California. Each California sober living home is designed to be
            comprehensive and reliable. Trust Taste Recovery as a guide on your
            road to recovery, even close to home.
          </p>
          <p>Consider sober living facilities in:</p>
          <ul className="list-style pl-4 ml-3 mb-2">
            <li className="mb-1">Los Angeles,</li>
            <li className="mb-1">Newport Beach,</li>
            <li className="mb-1">Orange County,</li>
            <li className="mb-1">And more.</li>
          </ul>
          <p>
            These facilities offer holistic, comprehensive, and personalized
            care to every patient. Whether you struggle with drug addiction,
            alcohol abuse, or something similar, you can find the next best step
            toward recovery.
          </p>
          <p>
            Click on any of the sober living homes in California above to learn
            more about a particular location. When you are ready, feel free to
            email or call that sober home’s admissions staff. They can help set
            up a tour.
          </p>

          <b>Get in touch with a California sober living home today.</b>
        </>
      ),
    },
  ];

  return (
    <div className="container" style={{ marginBottom: "200px" }}>
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">FREQUENTLY ASKED QUESTIONS</h2>
        </div>

        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Californiafaq;
