import React, { useState } from "react";
const Phpcontent = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Close if it's already expanded, else open
  };

  const faqData = [
    {
      question:
        "What is PHP and how does it differ from inpatient and outpatient treatment?",
      answer: (
        <>
          <p className="mb-2">
            PHP is an acronym that stands for “Partial Hospitalization Program.”
            It is designed as a mid-range option of treatment. Specifically, it
            is designed to help with certain mental conditions, substance abuse,
            and addiction.
          </p>
          <p>
            Think of a PHP program as more involved than an outpatient center
            but less so than a 24-hour supervised care program.
          </p>
          <p>
            PHP treatment programs administered through a licensed treatment
            facility are ideal for many patients. Specifically, those with
            average substance or behavioral health needs. Treatment options are
            tailored to their circumstances, with higher levels of care
            administered when deemed necessary.
          </p>
        </>
      ),
    },
    {
      question: "Who works with patients in a PHP rehab program?",
      answer: (
        <>
          <p className="mb-2">
            Each location should be serviced by licensed medical, behavioral
            health, and substance abuse professionals. Each patient gets access
            to the best in-class care delivered by a staff that ensures the care
            they need.
          </p>
        </>
      ),
    },
    {
      question: "What kinds of treatments and therapies are used in PHP rehab?",
      answer: (
        <>
          <p>
            PHP treatment can vary depending on the location and the staff that
            supports it. However, many of the centers are equipped to provide
            comprehensive PHP treatments and PHP therapy to incoming patients.
          </p>
          <p>These common approaches include:</p>
          <ul className="list-style pl-4 ml-3 mb-2">
            <li className="mb-1">Cognitive behavioral therapy,</li>
            <li className="mb-1">Support group therapy sessions,</li>
            <li className="mb-1">Individual counseling,</li>
            <li className="mb-1">Music and art therapy,</li>
            <li className="mb-1"> Trauma counseling.</li>
            <li className="mb-1">And more.</li>
          </ul>
        </>
      ),
    },
    {
      question: " How is PHP therapy treatment administered?",
      answer: (
        <p>
          While group therapies are conducted and administered to a group, this
          is not true for all. Many of the PHP treatments are individualized per
          patient. This means specialized care in the areas a patient most needs
          support, which can lead to better and more lasting outcomes.
        </p>
      ),
    },
    {
      question: "Can I really see an improvement from PHP on mental health?",
      answer: (
        <p>
          The goal of each PHP drug treatment and mental health support center
          is patient success. Centers strive to provide a foundation for healthy
          decisions, improvement-centric thinking, and better outcomes. Many
          patients are pleased with the process. They find the support they
          receive from these PHP mental health and substance interventions
          support their goals.
        </p>
      ),
    },
    {
      question: "How can I learn more about a particular PHP rehab center?",
      answer: (
        <p>
          We recommend browsing the list of partial hospitalization programs
          above. There’s a good chance there is a PHP treatment center located
          close to you. Once you have identified a PHP program that works for
          you, you can email or call the admissions team directly. They will
          assist you in setting up a tour.
        </p>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="faq-content">
        <div className="faq-content-block">
          <h2 className="faqs">
            Frequently Asked Questions about Taste Recovery PHP Rehab
          </h2>
        </div>

        {faqData.map((faq, index) => (
          <div key={index} className="faq-content-block accordion-block">
            <div
              className={`accordion-header ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span className="icon"></span>
            </div>
            {expandedIndex === index && (
              <div className="accordion-content">
                <div className="inner-content">{faq.answer}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Phpcontent;
